import React, { useContext } from 'react'
import PrivateRoute from './PrivateRoute.js'

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import { AuthContext } from './context/AuthContextProvider.tsx'

import Home from './Home.jsx'
import StudentHome from './Modules/StudentHome.jsx'
import InstructorHome from './Modules/InstructorHome.jsx'
import Courses from './Modules/Courses.jsx'
import Admins from './Modules/Admins.jsx'

function BrowserRouter() {
  const authContext = useContext(AuthContext)
  if (authContext.token === '' && authContext.user) { return <React.Fragment></React.Fragment> }

  let page = ''
  for (let each in authContext.user.roles) {
    if (authContext.user.roles[each]) {
      page = each.substring(2)
      break
    }
  }

  return (
    <Router>
      <Container fluid>
        <Row>
          <Routes>
            <Route exact path='/' element={<Navigate to={page} replace />} />

            <Route exact path='Instructor' element={
              <PrivateRoute isAllowed={authContext.isAuthenticated && authContext.user.roles.isInstructor}>
                <Home>
                  <InstructorHome accessToken={authContext.token} user={authContext.user} />
                </Home>
              </PrivateRoute>
            } />

            <Route exact path='Student' element={
              <PrivateRoute isAllowed={authContext.isAuthenticated && authContext.user.roles.isStudent}>
                <Home>
                  <StudentHome accessToken={authContext.token} />
                </Home>
              </PrivateRoute>
            } />

            <Route exact path='Courses' element={
              <PrivateRoute isAllowed={authContext.isAuthenticated && authContext.user.roles.isAdmin}>
                <Home>
                  <Courses accessToken={authContext.token} />
                </Home>
              </PrivateRoute>
            } />

            <Route exact path='Admin' element={
              <PrivateRoute isAllowed={authContext.isAuthenticated && authContext.user.roles.isAdmin}>
                <Home>
                  <Admins accessToken={authContext.token} />
                </Home>
              </PrivateRoute>
            } />
          </Routes>
        </Row>
      </Container>

      <ToastContainer
        containerId='toast'
        position='bottom-right'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        theme='colored'
        draggable
        pauseOnHover
      />
    </Router>
  )
}

export default BrowserRouter
