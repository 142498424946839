import React from 'react'
// import Sidebar from './Modules/sidebar.js'
import Input from '../Components/Input.jsx'
import Table from '../Components/Table.jsx'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { API } from 'aws-amplify'
import 'react-datepicker/dist/react-datepicker.css'

const columns = [{
  name: 'Email',
  selector: row => row['Email'],
  sortable: true
}]

class Admins extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      table: [],
      showModal: false,
      pending: false
    }
  }

  componentDidMount() {
    this.getAdmins()
  }

  getAdmins = () => {
    this.setState({ pending: true })

    API.get(process.env.REACT_APP_API_ENDPOINTS_NAME, '/administrators', { headers: { 'accesstoken': this.props.accessToken } }).then(result => {
      this.setState({ table: result, pending: false })
    }).catch(error => {
      console.error(error)
      this.setState({ pending: false })
      toast.error('There was a problem with the API.')
    })
  }

  handleOpen = () => { this.setState({ showModal: true }) }
  handleClose = () => { this.setState({ showModal: false }) }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }

  handleSave = () => {
    if (!this.state.email) {
      toast.warn('Email is required.')
      return
    }

    this.setState({ pending: true })

    API.post(process.env.REACT_APP_API_ENDPOINTS_NAME, '/administrators', {
      headers: {
        'accesstoken': this.props.accessToken,
        'email': this.state.email
      }
    }).then(result => {
      toast.success(result)
      this.handleClose()
      this.getAdmins()
      this.setState({ email: '' })
    }).catch(error => {
      console.error(error)
      this.setState({ pending: false })
      toast.error('There was a problem with the API.')
    })
  }

  render() {
    return (
      <React.Fragment>
        <Col xs={12} id='page-content-wrapper'>
          <div className='col-md-12'>
            <div className='component_container'>
              <Button data-cy='add-admin' className='genericButton' variant='primary' size='md' style={{ float: 'right' }} onClick={this.handleOpen}>Add Admin</Button>
              <p className='component_header'>Administrators</p>
              <hr className='line' />

              <Table
                columns={columns}
                fullData={this.state.table}
                progressPending={this.state.pending}
              />
            </div>
          </div>
        </Col>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Administrator</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row>
                <Col md={12}>
                  <Input data-cy='admin-email' label='Email' name='email' value={this.state.email} onBlur={this.handleChange} />
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            {this.state.pending && <Spinner animation='border' variant='info' />}
            <Button data-cy='save-admin' variant='primary' onClick={this.handleSave} disabled={this.state.pending}>Save Admin</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Admins