import React from 'react'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import moment from 'moment'
import Input from './Input.jsx'
import Button from 'react-bootstrap/Button'

const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress size={80} thickness={5} />
    </Box>
  )
}

const customStyles = {
  table: { style: { border: '1px solid #8E8C8C' } },
  headRow: { style: { borderBottom: '2px solid #8E8C8C' } },
  headCells: {
    style: {
      backgroundColor: '#D0EEFF',
      fontWeight: 'bold'
    }
  }
}

class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      filter: '',
      reset: true
    }
  }

  static getDerivedStateFromProps(prop, state) {
    if (state.filteredData && prop.fullData && state.data) {
      if ((state.reset && state.filteredData[0] !== prop.fullData[0]) || prop.fullData[0] !== state.data[0]) {
        return {
          data: prop.fullData,
          filteredData: prop.fullData,
          reset: false
        }
      }

      if (prop.fullData.length !== state.data.length) {
        return {
          data: prop.fullData,
          filteredData: prop.fullData,
          reset: false
        }
      }
    }


    return {}
  }

  filterTable = (e) => {
    e.preventDefault()
    let valueArr = this.state.filter.split(' ')
    let filtered = this.props.fullData

    filtered = filtered.filter(item => {
      let arr = []
      for (const index in valueArr) {
        for (const key in item) {
          if (key.includes('Date')) {
            if (item[key] && moment(Date.parse(item[key])).format('MM/DD/YYYY').includes(valueArr[index].toLowerCase())) {
              arr.push(true)
              break
            }
          } else if (item[key] && item[key].toString().toLowerCase().includes(valueArr[index].toLowerCase())) {
            arr.push(true)
            break
          }
        }

        if (arr[index] === undefined) {
          arr.push(false)
        }
      }

      for (const index in arr) {
        if (!arr[index]) {
          return false
        }
      }

      return true
    })

    if (filtered.length === 0) {
      toast.warn('No records found for the given filter.')
    }

    this.setState({
      filteredData: filtered,
      reset: filtered.length === 0
    })
  }

  handleChange = (e) => { this.setState({ filter: e.target.value }) }

  render() {
    return (
      <React.Fragment>
        {this.props.hasFilter && this.state.filteredData[0] &&
          <div className='row'>
            <div className='col-sm-10' style={{ float: 'left' }}>
              <form onSubmit={this.filterTable}>
                <div className='col-sm-8' style={{ display: 'inline-flex', padding: '0px' }}>
                  <Input value={this.state.filter} onBlur={this.handleChange} style={{ width: '36%', marginRight: '15px' }} />
                  <Button className='genericButton' type='submit' variant='primary' size='lg' onClick={this.filterTable}>Filter</Button>
                </div>
              </form>
            </div>
          </div>
        }

        <DataTable
          className='dataTable'
          customStyles={customStyles}
          data={this.state.filteredData}
          progressComponent={<CircularIndeterminate />}
          pagination={this.state.filteredData.length > 10}
          {...this.props}
        />
      </React.Fragment>
    )
  }
}

Table.defaultProps = { hasFilter: false }
export default Table