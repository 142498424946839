import React from 'react'
import Input from '../Components/Input.jsx'
import CustomizedLabel from '../Components/CustomizedLabel.js'
import { colorMap, conditionalStatus } from '../utils/colorMap.js'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from '../Components/Table.jsx'
import moment from 'moment'
import AWS from 'aws-sdk'
import Select from 'react-select'
import { Tooltip, PieChart, Pie } from 'recharts'
import { toast } from 'react-toastify'
import { API } from 'aws-amplify'

AWS.config.update({ region: 'us-east-2' });

const columns = [
  {
    name: 'Assignment',
    selector: row => row['assignment'],
    sortable: true
  }, {
    name: 'Platform',
    selector: row => row['platform'],
    sortable: true
  }, {
    name: 'Name',
    selector: row => row['name'],
    sortable: true
  }, {
    name: 'Description',
    selector: row => row['desc'],
    sortable: true,
    grow: 2
  }, {
    name: 'Status',
    selector: row => row['status'] + (row['score'] ? ' (' + row['score'] + '%)' : ''),
    sortable: true,
    conditionalCellStyles: conditionalStatus
  }, {
    name: 'Date',
    selector: row => row['date'],
    sortable: true
  }
]

class StudentHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      instructor: {},
      subject: '',
      emailBody: '',
      data: [],
      table: [],
      workLoad: [],
      allInfo: [],
      courses: [],
      selectedCourse: {},
      instructorEmails: [],
      showModal: false,
      pending: true
    }
  }

  componentDidMount() {
    API.get(process.env.REACT_APP_API_ENDPOINTS_NAME, '/students/statements', { headers: { 'accesstoken': this.props.accessToken } }).then(statementResults => {
      API.get(process.env.REACT_APP_API_ENDPOINTS_NAME, '/students/info', { headers: { 'accesstoken': this.props.accessToken } }).then(infoResults => {
        let arr = []
        let load = []
        let courses = []

        for (let elem of infoResults) { courses.push({ label: elem['Class Name'], value: elem['UUID'] }) }

        for (let obj of statementResults) {
          arr.push({
            id: obj.id,
            assignment: obj.object.objectType,
            platform: obj.context.platform,
            class: obj.context.registration,
            name: obj.object.definition.name['en-US'],
            desc: obj.object.definition.description['en-US'],
            status: obj.verb.display['en-US'],
            score: obj.result && obj.result.score.raw,
            date: moment(Date.parse(obj.timestamp)).format('MM/DD/YYYY')
          })

          let newElem = true
          for (let elem of load) {
            if (elem.name === obj.verb.display['en-US']) {
              elem.count++
              newElem = false
              break
            }
          }

          if (newElem) {
            load.push({ name: obj.verb.display['en-US'], count: 1, fill: colorMap[obj.verb.display['en-US']] })
          }
        }

        this.setState({
          data: statementResults,
          table: arr,
          workLoad: load,
          allInfo: infoResults,
          courses: courses,
          selectedCourse: courses[0],
          instructorEmails: infoResults[0]['Instructor List'].map(elem => ({ label: elem, value: elem })),
          pending: false
        })
      }).catch(error => {
        console.error(error)
        toast.error('There was a problem with the API request.')
      })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem with xAPI.')
    })
  }

  handleOpen = () => { this.setState({ showModal: true }) }
  handleClose = () => { this.setState({ showModal: false }) }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleInstructorChange = (value) => { this.setState({ instructor: value }) }

  render() {
    return (
      <div className='row' >
        <div className='col-md-12'>
          <div className='component_container' style={{ minHeight: '378px' }}>
            <div className='row'>
              <div className='col-md-1'>
                <p className='component_header'>Assignments</p>
              </div>

              <div className='col-md-3' style={{ paddingLeft: '40px' }}>
                <Select value={this.state.selectedCourse} onChange={this.handleInstructorChange} options={this.state.courses} />
              </div>

              <div className='col-md-8'>
                <Button className='genericButton' variant='primary' size='md' style={{ float: 'right' }} onClick={this.handleOpen}>Contact Instructor</Button>
              </div>
            </div>
            <hr className='line' />

            <Table
              columns={columns}
              fullData={this.state.table.filter(elem => elem.class === this.state.selectedCourse.value)}
              progressPending={this.state.pending}
              pagination={this.state.table.filter(elem => elem.class === this.state.selectedCourse.value).length > 5}
            />
          </div>
        </div>

        <div className='col-md-4'>
          <div data-cy='student-courses' className='component_container'>
            <p className='component_header'>Courses</p>
            <hr className='line' />

            <Table
              columns={[
                {
                  name: 'Course',
                  selector: row => row['Class Name'],
                  sortable: true
                }, {
                  name: 'Start Date',
                  selector: row => moment(row['Start Date'].split('T')[0], "YYYYMMDD").format('MM/DD/YYYY'),
                  sortable: true
                }, {
                  name: 'End Date',
                  selector: row => moment(row['End Date'].split('T')[0], "YYYYMMDD").format('MM/DD/YYYY'),
                  sortable: true
                }
              ]}
              fullData={[...new Map(this.state.allInfo.map(item => [item['Class Name'], item])).values()]}
              progressPending={this.state.pending}
            />
          </div>
        </div>

        <div className='col-md-4'>
          <div className='component_container'>
            <p className='component_header'>To-Do</p>
            <hr className='line' />

            <Table
              columns={[
                {
                  name: 'Description',
                  selector: row => row['desc'],
                  sortable: true,
                  grow: 2
                }, {
                  name: 'Status',
                  selector: row => row['status'],
                  sortable: true
                }
              ]}
              fullData={this.state.table.filter(row => row.status !== 'failed' && row.status !== 'completed')}
              progressPending={this.state.pending}
            />
          </div>
        </div>

        <div className='col-md-4'>
          <div className='component_container'>
            <p className='component_header'>Assigned Training</p>
            <hr className='line' />

            <PieChart width={450} height={380}>
              <Pie data={this.state.workLoad} dataKey='count' label={CustomizedLabel} labelLine={false} />
              <Tooltip />
            </PieChart>
          </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Instructor</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row>
                <Col md={12}>
                  <label>Instructor Email</label>
                  <Select value={this.state.instructor} onChange={this.handleInstructorChange} options={this.state.instructorEmails} />
                  <Input label='Subject' name='subject' value={this.state.subject} onBlur={this.handleChange} />
                  {/* <label>Message</label> */}
                  {/* <textarea className='genericInput' name='emailBody' rows='5' value={this.state.emailBody} onChange={this.handleChange} /> */}
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <a href={`mailto:${this.state.instructor && this.state.instructor.value}?subject=${this.state.subject}`}>
              <Button variant='primary' onClick={this.handleClose}>Send Email</Button>
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default StudentHome
