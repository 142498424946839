const colorMap = {
  'launched': 'Blue',
  'initialized': 'Yellow',
  'completed': 'Green',
  'terminated': 'Black',
  'attempted': 'Orange',
  'failed': 'Red'
}

const conditionalStatus = Object.keys(colorMap).map(key => {
  return {
    when: row => row['status'] === key,
    style: { backgroundColor: colorMap[key], color: colorMap[key] === 'Yellow' ? 'Black' : 'White' }
  }
})

export { colorMap, conditionalStatus }