import React, { useContext, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContextProvider.tsx'

const Sidebar = props => {
  const authContext = useContext(AuthContext)
  const [styleObj, setStyleObj] = useState({ backgroundColor: '#d0eeff' })

  const handleLogout = () => {
    localStorage.clear()
    authContext.logout()
  }

  useEffect(() => {
    setStyleObj({ [window.location.pathname]: { backgroundColor: '#d0eeff' } })
  }, [setStyleObj])

  let count = 0
  for (let each in authContext.user.roles) {
    if (authContext.user.roles[each]) {
      if (each === 'isAdmin') { count++ }
      count++
    }
  }

  return (
    <React.Fragment>
      <div style={{ textAlignLast: 'center' }}>
        <Link to='/Instructor'>
          <img src={process.env.PUBLIC_URL + '/images/BETAFLIX-logo.png'} alt='BetaFlix-logo' height='50' width='100%' />
        </Link>
      </div>

      <div className='sidebar-sticky'></div>

      {count > 1 &&
        <Nav className='flex-column'>
          {authContext.user.roles.isAdmin && <Nav.Link className='navigation-box' style={styleObj['/Admin']} data-cy='link-admin' href='/Admin'>
            <div>Admin</div>
          </Nav.Link>}

          {authContext.user.roles.isAdmin && <Nav.Link className='navigation-box' style={styleObj['/Courses']} data-cy='link-courses' href='/Courses'>
            <div>Courses</div>
          </Nav.Link>}

          {authContext.user.roles.isInstructor && <Nav.Link className='navigation-box' style={styleObj['/Instructor']} data-cy='link-instructor' href='/Instructor'>
            <div>Instructor</div>
          </Nav.Link>}

          {authContext.user.roles.isStudent && <Nav.Link className='navigation-box' style={styleObj['/Student']} data-cy='link-student' href='/Student'>
            <div>Student</div>
          </Nav.Link>}

          <hr className='navigation-line' />
        </Nav>
      }

      <div className='sidebar-sticky'></div>

      <div style={{ textAlign: '-webkit-center' }}>
        <Button data-cy='logout' className='genericButton' variant='primary' size='md' onClick={handleLogout}>Logout</Button>
      </div>
    </React.Fragment >
  )
}

export default Sidebar
