import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js'
import React, { createContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'

/**
 * KeycloakConfig configures the connection to the Keycloak server.
 */
const keycloakConfig: KeycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  url: process.env.REACT_APP_KEYCLOAK_URL
}

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const keycloakInitOptions: KeycloakInitOptions = {
  // Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page). If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
  onLoad: 'login-required',
  silentCheckSsoRedirectUri: '/',
  checkLoginIframe: false
}

// Create the Keycloak client instance
const keycloak = Keycloak(keycloakConfig)

/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  /**
   * Whether or not a user is currently authenticated
   */
  isAuthenticated: boolean

  /**
   * Function to initiate the logout
   */
  logout: () => void

  token: string,
  user: object
}

/**
 * Default values for the {@link AuthContext}
 */
const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  logout: () => { },
  token: '',
  user: {}
}

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
)

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props: AuthContextProviderProps) => {
  // Create the local state in which we will keep track if a user is authenticated
  const [isAuthenticated, setAuthenticated] = useState<boolean>(true)
  const [token, setToken] = useState<string>('')
  const [user, setUser] = useState<object>({})

  const logout = () => {
    keycloak.logout()
  }

  useEffect(() => {
    /**
     * Initialize the Keycloak instance
     */
    async function initializeKeycloak() {
      try {
        const isAuthenticatedResponse = await keycloak.init(keycloakInitOptions)
        if (!isAuthenticatedResponse) { keycloak.login() }
        const user = await keycloak.loadUserInfo()
        const roles = await API.get(process.env.REACT_APP_API_ENDPOINTS_NAME || '', '/roles', { headers: { 'accesstoken': keycloak.token || '' } })
        user['roles'] = roles
        setToken(keycloak.token || '')
        setAuthenticated(isAuthenticatedResponse)
        setUser(user)
      } catch (e) {
        console.error('error initializing Keycloak:', e.error)
        setAuthenticated(false)
      }
    }

    initializeKeycloak()
  }, [])

  return (
    <AuthContext.Provider value={{ isAuthenticated, logout, token, user }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider