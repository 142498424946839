import React from 'react'
import Input from '../Components/Input.jsx'
import Table from '../Components/Table.jsx'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { API } from 'aws-amplify'
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import 'react-datepicker/dist/react-datepicker.css'

// const ExpandedComponent = ({ data }) => {
//   return (
//     <Row style={{ backgroundColor: '#F1FAFD', padding: '10px 0px 10px 0px', overflow: 'hidden' }}>
//       <Col></Col>
//       <Col>
//         <h5>Instructors</h5>
//         <ul data-cy='instructor-list'>{data['Instructor List'].map(elem => <li key={Math.random()}>{elem}</li>)}</ul>
//       </Col>
//       <Col></Col>
//       <Col>
//         <h5>Students</h5>
//         <ul data-cy='student-list'>{data['Student List'].map(elem => <li key={Math.random()}>{elem}</li>)}</ul>
//       </Col>
//       <Col></Col>
//     </Row>
//   )
// }

class Courses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uuid: '',
      name: '',
      desc: '',
      teach: '',
      studentEmails: '',
      table: [],
      startDate: null,
      endDate: null,
      showModal: false,
      pending: false,
      isViewing: true,
      columns: [
        {
          name: 'Class Name',
          selector: row => row['Class Name'],
          sortable: true
        }, {
          name: 'Class Description',
          selector: row => row['Class Description'],
          sortable: true,
          grow: 2
        }, {
          name: 'Start Date',
          selector: row => moment(row['Start Date'].split('T')[0], "YYYYMMDD").format('MM/DD/YYYY'),
          sortable: true
        }, {
          name: 'End Date',
          selector: row => moment(row['End Date'].split('T')[0], "YYYYMMDD").format('MM/DD/YYYY'),
          sortable: true
        }, {
          name: 'Utilities',
          selector: row => (<React.Fragment>
            <Button data-cy='delete-class' variant='danger' size='sm' style={{ float: 'left', marginRight: '5px' }} onClick={() => this.handleDelete(row)} disabled={this.state.pending}><FaTrashAlt /></Button>
            <Button data-cy='edit-class' variant='info' size='sm' style={{ float: 'right', marginLeft: '5px' }} onClick={() => this.handleEdit(row)} disabled={this.state.pending}><FaEdit /></Button>
          </React.Fragment>),
          sortable: true
        }
      ]
    }
  }

  componentDidMount() { this.getCourses() }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleOpen = () => { this.setState({ showModal: true, isViewing: false }) }
  handleClose = () => {
    this.setState({
      name: '',
      desc: '',
      teach: '',
      studentEmails: '',
      startDate: null,
      endDate: null,
      showModal: false
    })
  }

  onDateChange = (dates) => {
    if (this.state.isViewing) { return }
    const [start, end] = dates
    this.setState({ startDate: start })
    this.setState({ endDate: end })
  }

  getCourses = () => {
    this.setState({ pending: true })
    API.get(process.env.REACT_APP_API_ENDPOINTS_NAME, '/administrators/classes', { headers: { 'accesstoken': this.props.accessToken } }).then(result => {
      this.setState({ table: result, pending: false })
    }).catch(error => {
      console.error(error)
      this.setState({ pending: false })
      toast.error('There was a problem with the API.')
    })
  }

  handleSave = () => {
    if (!this.state.name || !this.state.desc || !this.state.startDate || !this.state.endDate || !this.state.teach || !this.state.studentEmails) {
      toast.warn('All Fields are required.')
      return
    }

    this.setState({ pending: true })

    API.post(process.env.REACT_APP_API_ENDPOINTS_NAME, '/administrators/classes', {
      headers: { 'accesstoken': this.props.accessToken },
      body: {
        'Instructor List': this.state.teach.replaceAll(/\n/g, '').replaceAll(' ', '').split(','),
        'Student List': this.state.studentEmails.replaceAll(/\n/g, '').replaceAll(' ', '').split(','),
        'Start Date': this.state.startDate.toISOString().replaceAll('-', '').split('.')[0],
        'End Date': this.state.endDate.toISOString().replaceAll('-', '').split('.')[0],
        'Class Name': this.state.name,
        'Class Description': this.state.desc
      }
    }).then(result => {
      toast.success('Course Created: ' + result)
      this.handleClose()
      this.setState({
        name: '',
        desc: '',
        startDate: null,
        endDate: null,
        teach: '',
        studentEmails: '',
        pending: false
      })
      this.getCourses()
    }).catch(error => {
      console.error(error)
      this.setState({ pending: false })
      toast.error('There was a problem with the API.')
    })
  }

  handleDelete = row => {
    if (!window.confirm('Are you sure you want to delete this Course?')) { return }
    this.setState({ pending: true })

    API.del(process.env.REACT_APP_API_ENDPOINTS_NAME, '/administrators/classes', {
      headers: { 'accesstoken': this.props.accessToken },
      body: {
        'Instructor List': row['Instructor List'],
        'Student List': row['Student List'],
        'UUID': row['UUID']
      }
    }).then(result => {
      toast.success(result)
      this.getCourses()
    }).catch(error => {
      console.error(error)
      this.setState({ pending: false })
      toast.error('There was a problem with the API.')
    })
  }

  viewCourse = row => {
    this.setState({
      uuid: row['UUID'],
      name: row['Class Name'],
      desc: row['Class Description'],
      teach: row['Instructor List'].join(', '),
      studentEmails: row['Student List'].join(',\n'),
      startDate: new Date(moment(row['Start Date'].split('T')[0], "YYYYMMDD").format('YYYY-MM-DD')),
      endDate: new Date(moment(row['End Date'].split('T')[0], "YYYYMMDD").format('YYYY-MM-DD')),
      showModal: true,
      isViewing: true
    })
  }

  handleEdit = row => {
    this.setState({
      uuid: row['UUID'],
      name: row['Class Name'],
      desc: row['Class Description'],
      teach: row['Instructor List'].join(', '),
      studentEmails: row['Student List'].join(',\n'),
      startDate: new Date(moment(row['Start Date'].split('T')[0], "YYYYMMDD").format('YYYY-MM-DD')),
      endDate: new Date(moment(row['End Date'].split('T')[0], "YYYYMMDD").format('YYYY-MM-DD')),
      showModal: true,
      isViewing: false
    })
  }

  render() {
    return (
      <React.Fragment>
        <Col xs={12} id='page-content-wrapper'>
          <div className='col-md-12'>
            <div className='component_container'>
              <Button data-cy='add-class' className='genericButton' variant='primary' size='md' style={{ float: 'right' }} onClick={this.handleOpen} disabled={this.state.pending}>Add Class</Button>
              <p className='component_header'>Courses</p>
              <hr className='line' />

              <Table
                columns={this.state.columns}
                fullData={this.state.table}
                progressPending={this.state.pending}
                onRowClicked={this.viewCourse}
              />
            </div>
          </div>
        </Col>

        <Modal show={this.state.showModal} onHide={this.handleClose} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.name || 'Add Class'}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row>
                <Col md={6}>
                  <Input data-cy='class-name' label='Class Name' name='name' value={this.state.name} onBlur={this.handleChange} disabled={this.state.isViewing} />
                  <label>Class Description</label>
                  <textarea data-cy='class-desc' className='genericInput' name='desc' rows='5' value={this.state.desc} onChange={this.handleChange} disabled={this.state.isViewing} />
                </Col>

                <Col md={6}>
                  <Input data-cy='instructor' label='Instructor' name='teach' value={this.state.teach} onBlur={this.handleChange} disabled={this.state.isViewing} />
                  <label>Students Emails (Comma Seperated)</label>
                  <textarea data-cy='students' className='genericInput' name='studentEmails' rows='5' value={this.state.studentEmails} onChange={this.handleChange} disabled={this.state.isViewing} />
                </Col>
              </Row>

              <Row data-cy='class-dates' style={{ textAlign: '-webkit-center' }}>
                <label>Class Dates</label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.onDateChange}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  selectsRange
                  inline
                />
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            {this.state.pending && <Spinner animation='border' variant='info' />}
            {this.state.isViewing ?
              // <React.Fragment></React.Fragment>
              <Button data-cy='edit-class' variant='primary' onClick={() => this.setState({ isViewing: false })} >Edit Class</Button>
              :
              <Button data-cy='save-class' variant='primary' onClick={this.handleSave} disabled={this.state.pending}>Save Class</Button>
            }
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Courses