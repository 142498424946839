import React from 'react'
import Table from '../Components/Table.jsx'
import CustomizedLabel from '../Components/CustomizedLabel.js'
import { colorMap, conditionalStatus } from '../utils/colorMap.js'
import AddRecord from './AddRecord.jsx'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import moment from 'moment'
import { Tooltip, PieChart, Pie } from 'recharts'
import { API } from 'aws-amplify'
import { toast } from 'react-toastify'
import { AiOutlineMail } from 'react-icons/ai'

const courseAnalytics = [
  {
    course: 'Test Course',
    average: 87,
    grades: [
      { name: 'Passing', count: 44, fill: 'Green' },
      { name: 'At Risk', count: 15, fill: 'Yellow' },
      { name: 'Failing', count: 25, fill: 'Red' }
    ]
  },
  {
    course: 'Test Course 2',
    average: 92,
    grades: [
      { name: 'Passing', count: 50, fill: 'Green' },
      { name: 'At Risk', count: 10, fill: 'Yellow' },
      { name: 'Failing', count: 8, fill: 'Red' }
    ]
  }
]

const courseOverview = [
  { name: 'First Last', email: 'first.last@email.com', assignment_1: 'Completed', assignment_2: 'Completed', assignment_3: 'Completed', assignment_4: 'Completed', average: 90 },
  { name: 'First Last', email: 'first.last@email.com', assignment_1: 'Completed', assignment_2: 'Failed', assignment_3: 'Launched', assignment_4: 'Initialized', average: 40 },
  { name: 'First Last', email: 'first.last@email.com', assignment_1: 'Completed', assignment_2: 'Completed', assignment_3: 'Failed', assignment_4: 'Initialized', average: 60 },
  { name: 'First Last', email: 'first.last@email.com', assignment_1: 'Failed', assignment_2: 'Launched', assignment_3: 'Initialized', assignment_4: 'Initialized', average: 20 }
]

const colorMapping = {
  Completed: 'success',
  Failed: 'danger',
  Attempted: 'secondary',
  Launched: 'primary',
  Terminated: 'dark',
  Initialized: 'warning',
}

const overviewColumns = [{
  name: 'Name',
  selector: row => row.name,
  sortable: true
}, {
  name: 'Email',
  selector: row => row.email,
  sortable: true
}, {
  name: 'Assignment 1',
  selector: row => (<a href={`mailto:${row.email}?subject=${'Assignment 1 - ' + row.assignment_1}`}>
    <Button key={Math.random()} size='sm' style={{ marginRight: '2px' }} variant={colorMapping[row.assignment_1]}>{row.assignment_1}</Button>
  </a>),
  sortable: true
}, {
  name: 'Assignment 2',
  selector: row => (<a href={`mailto:${row.email}?subject=${'Assignment 1 - ' + row.assignment_1}`}>
    <Button key={Math.random()} size='sm' style={{ marginRight: '2px' }} variant={colorMapping[row.assignment_2]}>{row.assignment_2}</Button>
  </a>),
  sortable: true
}, {
  name: 'Assignment 3',
  selector: row => (<a href={`mailto:${row.email}?subject=${'Assignment 1 - ' + row.assignment_1}`}>
    <Button key={Math.random()} size='sm' style={{ marginRight: '2px' }} variant={colorMapping[row.assignment_3]}>{row.assignment_3}</Button >
  </a>),
  sortable: true
}, {
  name: 'Assignment 4',
  selector: row => (<a href={`mailto:${row.email}?subject=${'Assignment 1 - ' + row.assignment_1}`}>
    <Button key={Math.random()} size='sm' style={{ marginRight: '2px' }} variant={colorMapping[row.assignment_4]}>{row.assignment_4}</Button >
  </a>),
  sortable: true
}, {
  name: 'Average',
  selector: row => row.average,
  sortable: true,
  conditionalCellStyles: [
    {
      when: row => row['average'] >= 75,
      style: { backgroundColor: 'green', color: 'white' }
    }, {
      when: row => row['average'] < 75 && row['average'] >= 50,
      style: { backgroundColor: 'yellow' }
    }, {
      when: row => row['average'] < 50,
      style: { backgroundColor: 'red', color: 'white' }
    }
  ]
}]

const courseColumns = [
  {
    name: 'Course',
    selector: row => row['course'],
    sortable: true
  }
]

const studentColumns = [
  {
    name: 'Email',
    selector: row => row['email'],
    sortable: true
  }
]

const statementColumns = [
  {
    name: 'Assignment',
    selector: row => row['assignment'],
    sortable: true
  }, {
    name: 'Desctiption',
    selector: row => row['desc'],
    sortable: true
  }, {
    name: 'Status',
    selector: row => row['status'],
    sortable: true,
    conditionalCellStyles: conditionalStatus
  }, {
    name: 'Date',
    selector: row => row['date'],
    sortable: true
  }, {
    name: 'Send Email',
    selector: row => <a href={`${row['email']}?subject=${row['course']}: ${row['desc']}`}><AiOutlineMail /></a>,
    sortable: true
  }
]

class InstructorHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      courses: [],
      students: [],
      student: {},
      emailAddress: '',
      subject: '',
      isAnimated: true,
      pending: true,
      studentPending: true,
      show: false,
      createShow: false
    }
  }

  componentDidMount() {
    API.get(process.env.REACT_APP_API_ENDPOINTS_NAME, '/instructors/info', { headers: { 'accesstoken': this.props.accessToken } }).then(result => {
      let arr = []

      for (let each of result) {
        arr.push({
          uuid: each['UUID'],
          course: each['Class Name']
        })
      }

      this.setState({ courses: arr, data: result, pending: false })
      this.getStudents(arr[0].uuid, result)
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem with the API.')
    })
  }

  getStudents = (uuid, data = null) => {
    let arr = []
    let flag = true
    if (!data) {
      data = this.state.data
      flag = false
    }

    for (let each of data) {
      if (uuid === each['UUID']) {
        arr = each['Student List'].map(elem => { return { uuid: each['UUID'], course: each['Class Name'], email: elem } })
      }
    }

    this.setState({ students: arr, isAnimated: flag })
  }

  getStudentInfo = ({ uuid, course, email }) => {
    this.setState({ show: true, student: {}, studentPending: true })

    API.get(process.env.REACT_APP_API_ENDPOINTS_NAME, '/instructors/statements', {
      headers: {
        'accesstoken': this.props.accessToken,
        'UUID': uuid,
        'studentemail': email
      }
    }).then(result => {
      result = JSON.parse(result)
      if (result.length === 0 || result === '[]') {
        toast.warn('Student has no assignemnts.')
        this.setState({ student: { uuid: uuid, course: course, email: email }, studentPending: false })
        return
      }

      let arr = []
      let load = []

      for (let obj of result) {
        arr.push({
          email: obj.actor.mbox,
          course: obj.context && obj.context.platform,
          assignment: obj.object.objectType,
          desc: obj.object.definition.name['en-US'],
          status: obj.verb.display['en-US'],
          date: moment(Date.parse(obj.timestamp)).format('MM/DD/YYYY')
        })

        let newElem = true
        for (let elem of load) {
          if (elem.name === obj.verb.display['en-US']) {
            elem.count++
            newElem = false
            break
          }
        }

        if (newElem) {
          load.push({ name: obj.verb.display['en-US'], count: 1, fill: colorMap[obj.verb.display['en-US']] })
        }
      }

      let className = ''
      for (let each of this.state.data) {
        if (uuid === each['UUID']) {
          className = each['Class Name']
          break
        }
      }

      let obj = {
        uuid: uuid,
        name: result[0] && result[0].actor.name,
        email: email,
        course: className,
        table: arr,
        load: load,
        raw: result
      }

      this.setState({ student: obj, studentPending: false })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem with xAPI.')
    })
  }

  handleClose = () => { this.setState({ show: false, studentPending: true, student: {} }) }
  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleCreate = () => { this.setState({ createShow: true }) }

  handleCreateClose = () => {
    this.setState({ createShow: false, studentPending: true })
    this.getStudentInfo(this.state.student)
  }

  render() {
    return (
      <div className='row' >
        <div className='col-md-12'>
          <div className='component_container'>
            <p className='component_header'>Course Analytics - Demo</p>
            <hr className='line' />

            <div className='analytics'>
              {courseAnalytics.map(elem =>
                <div key={Math.random()}>
                  <h5>{elem.course}: {elem.average}</h5>
                  <PieChart width={315} height={265}>
                    <Pie data={elem.grades} dataKey='count' label={CustomizedLabel} labelLine={false} />
                    <Tooltip />
                  </PieChart>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='col-md-3'>
          <div className='component_container' style={{ minHeight: '455px' }}>
            <p className='component_header'>Course List</p>
            <hr className='line' />

            <Table
              columns={courseColumns}
              fullData={this.state.courses}
              progressPending={this.state.pending}
              paginationComponentOptions={{ noRowsPerPage: true }}
              onRowClicked={(row) => this.getStudents(row.uuid)}
            />
          </div>
        </div>

        <div className='col-md-3'>
          <div className='component_container' style={{ minHeight: '455px' }}>
            <p className='component_header'>Student List</p>
            <hr className='line' />

            <Table
              columns={studentColumns}
              fullData={this.state.students}
              progressPending={this.state.pending}
              onRowClicked={(row) => this.getStudentInfo(row)}
            />
          </div>
        </div>

        <div className='col-md-6'>
          <div className='component_container' style={{ minHeight: '455px' }}>
            <p className='component_header'>Course Progress - Demo</p>
            <hr className='line' />

            <Table
              columns={overviewColumns}
              fullData={courseOverview}
              progressPending={this.state.pending}
            />
          </div>
        </div>

        <Modal data-cy='assignment-modal' show={this.state.show} onHide={this.handleClose} dialogClassName='modal-90w'>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.student.name || this.state.student.email}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row>
                <Col md={7} data-cy='assignment-table'>
                  <Table
                    columns={statementColumns}
                    fullData={this.state.student.table}
                    progressPending={this.state.studentPending}
                  />
                </Col>

                <Col md={5} data-cy='assignment-chart'>
                  <PieChart width={450} height={400}>
                    <Pie data={this.state.student.load} dataKey='count' label={CustomizedLabel} labelLine={false} />
                    <Tooltip />
                  </PieChart>
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button data-cy='create-statement' variant='primary' onClick={this.handleCreate} disabled={this.state.studentPending}>Create Statement</Button>
          </Modal.Footer>
        </Modal>

        {Object.keys(this.state.student).length > 0 && <AddRecord show={this.state.createShow} onHide={this.handleCreateClose} student={this.state.student} user={this.props.user} accessToken={this.props.accessToken} />}
      </div>
    )
  }
}

export default InstructorHome
