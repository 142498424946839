const CustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, fill }) => {
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} cx={cx} cy={cy} fill={fill === 'Yellow' ? 'Black' : 'White'} textAnchor={x > cx ? 'start' : 'end'}>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export default CustomizedLabel