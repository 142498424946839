import React from 'react'

class Input extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value || '' }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <input
          className='genericInput'
          type={this.props.type || 'text'}
          value={this.state.value}
          onChange={(e) => this.setState({ value: e.target.value })}
          name={this.props.name}
          style={this.props.style}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled}
          data-cy={this.props['data-cy']}
        />
      </React.Fragment>
    )
  }
}

export default Input
