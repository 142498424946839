import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './tests/reportWebVitals.js'
import BrowserRouter from './BrowserRouter.jsx'
import AuthContextProvider from './context/AuthContextProvider.tsx'
import { Amplify } from 'aws-amplify'

const root = ReactDOM.createRoot(document.getElementById('root'))

Amplify.configure({
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_ENDPOINTS_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINTS_ENDPOINT
      }
    ]
  }
})

window.onbeforeunload = function (e) {
  window.localStorage.unloadTime = JSON.stringify(new Date())
}

window.onload = function () {
  let unloadTime
  let loadTime = new Date()
  if (window.localStorage.unloadTime) {
    unloadTime = new Date(JSON.parse(window.localStorage.unloadTime))
  } else {
    unloadTime = new Date()
  }

  let refreshTime = loadTime.getTime() - unloadTime.getTime()

  if (refreshTime > 2000) {
    localStorage.clear()
    window.location = `/`
  }
}

root.render(
  <AuthContextProvider>
    <BrowserRouter />
  </AuthContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
