import React from 'react'
import Sidebar from './Modules/sidebar.js'
import { Col } from 'react-bootstrap'

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Col xs={1} id='sidebar-wrapper'>
          <Sidebar />
        </Col>

        <Col xs={1} />

        <Col xs={11} id='page-content-wrapper'>
          {this.props.children}
        </Col>
      </React.Fragment>
    )
  }
}

export default Home