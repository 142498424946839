import React from 'react'
import Input from '../Components/Input.jsx'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'
import { API } from 'aws-amplify'

const verbs = [
  { label: 'Attempted', value: 'attempted' },
  { label: 'Completed', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'Initialized', value: 'initialized' },
  { label: 'Launched', value: 'launched' },
  { label: 'Terminated', value: 'terminated' }
]

class AddRecord extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verb: {},
      definition: '',
      name: props.student.name,
      pending: false
    }
  }

  saveStatement = () => {
    let { pending, ...state } = this.state

    for (let each in state) {
      if (state[each] === '' || Object.keys(state[each]).length === 0) {
        toast.warn('All fields are required.')
        return
      }
    }

    this.setState({ pending: true })

    const body = {
      'object': {
        'id': 'http://adlnet.gov/expapi/activities/example',
        'definition': {
          'name': {
            'en-US': this.state.definition
          },
          'description': {
            'en-US': this.state.definition
          }
        },
        'objectType': 'Activity'
      },
      'authority': {
        'account': {
          'homePage': 'http://example.org',
          'name': '0188bd81-6e08-8fc5-a061-ca10b7d57e60'
        },
        'objectType': 'Agent'
      },
      'verb': {
        'id': 'http://adlnet.gov/expapi/verbs/' + this.state.verb.value,
        'display': {
          'en-US': this.state.verb.value
        }
      },
      'context': {
        'platform': this.props.student.course,
        'registration': this.props.student.uuid,
        'instructor': {
          'mbox': 'mailto:' + this.props.user.email,
          'name': this.props.user.name
        }
      },
      'actor': {
        'mbox': 'mailto:' + this.props.student.email,
        'name': this.state.name,
        'objectType': 'Agent'
      }
    }

    const obj = {
      body: body,
      headers: { 'accesstoken': this.props.accessToken }
    }

    API.post(process.env.REACT_APP_API_ENDPOINTS_NAME, '/instructors/statements', obj).then(res => {
      toast.success('Saved Statement')
      this.onHide()
      this.setState({ pending: false })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem with xAPI.')
      this.setState({ pending: false })
    })
  }

  handleChange = (e) => { this.setState({ [e.target.name]: e.target.value }) }
  handleStatusChange = (value) => { this.setState({ verb: value }) }
  onHide = () => {
    this.setState({ verb: {}, definition: '', name: '', pending: false })
    this.props.onHide()
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Statement</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={4}>
              <Input name='name' label='Student Name' value={this.state.name} onBlur={this.handleChange} disabled={this.props.student.name || this.state.pending} />
              <Input name='mbox' label='Student E-mail' value={this.props.student.email} disabled />
            </Col>

            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Input name='platform' label='Course' value={this.props.student.course} disabled />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Input name='instructorName' label='Instructor Name' value={this.props.user.name} disabled />
                </Col>

                <Col md={6}>
                  <Input name='instructorMbox' label='Instructor Email' value={this.props.user.email} disabled />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <label>Status</label>
              <Select id='verbSelector' value={this.state.verb} onChange={this.handleStatusChange} options={verbs} isDisabled={this.state.pending} />
            </Col>

            <Col md={8}>
              <Input name='definition' label='Assignment' value={this.state.definition} onBlur={this.handleChange} disabled={this.state.pending} />
            </Col>
          </Row>

        </Modal.Body>

        <Modal.Footer>
          {this.state.pending && <Spinner animation='border' variant='info' />}
          <Button data-cy='save-statement' variant='primary' onClick={this.saveStatement} disabled={this.state.pending}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddRecord
